import { useQuery } from '@tanstack/react-query'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { organizationsOwnedQuery, organizationsUsersQuery } from '../queries'

import { DeleteUser } from './DeleteUser'

export const ActiveUsersList = () => {
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: activeUsers } = useQuery(organizationsUsersQuery(isOrgAccount))
  const { data: userOrgs } = useQuery(organizationsOwnedQuery())
  const isOrgOwner = userOrgs?.some(user => user.id === String(decodedToken?.organization_id))

  return (
    <Stack space="xsmall">
      <Text size="medium" color="gray600">
        Active members
      </Text>
      {activeUsers?.length ? (
        activeUsers.map(user => (
          <Stack direction="horizontal" justify="between" align="center" key={user.email}>
            <Stack direction={'horizontal'} justify={'start'} align={'start'}>
              <Box>
                <Text>{user.givenName + ' ' + user.familyName}</Text>
              </Box>
              {/* org owner tag */}
              {user?.owner && (
                <Box
                  backgroundColor={'green500'}
                  padding={'xxsmall'}
                  paddingX={'small'}
                  borderRadius={'medium'}
                >
                  <Text size={'small'} color="white">
                    Owner
                  </Text>
                </Box>
              )}
              {/* read_only user tag */}
              {user?.readOnly && (
                <Box
                  backgroundColor={'blue500'}
                  padding={'xxsmall'}
                  paddingX={'small'}
                  borderRadius={'medium'}
                  maxWidth={'large'}
                >
                  <Text size={'small'} color="white">
                    Read-Only
                  </Text>
                </Box>
              )}
            </Stack>

            <Stack direction="horizontal" align="center">
              {user.email}
            </Stack>
            {isOrgOwner && (
              <Box>
                <DeleteUser userId={user.id} />
              </Box>
            )}
          </Stack>
        ))
      ) : (
        <Text>There are no users yet.</Text>
      )}
    </Stack>
  )
}
