import { type ReactElement, type SVGProps, useRef } from 'react'
import { type AriaTextFieldOptions, mergeProps, useFocusRing, useTextField } from 'react-aria'

import { Info } from '~/components/Icons'

import { Box } from '../Box'
import { Icon } from '../Icon'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { Tooltip, type TooltipProps } from '../Tooltip'

import * as styles from './Input.css'

export type InputProps = {
  /**
   * Adds an icon in the left-hand side of the input
   */
  prefix?: ReactElement<SVGProps<SVGSVGElement>>
  /**
   * Adds an icon in the right-hand side of the input
   */
  suffix?: ReactElement<SVGProps<SVGSVGElement>>
  /**
   * Display a helper icon next to the label with a tooltip on hover to guide the users.
   */
  tooltip?: TooltipProps['content']

  /**
   * validation error message
   **/
  validationErrorMessage?: any

  /**
   * validation error state
   **/
  isValidationError?: boolean
  errorMessage?: any
} & AriaTextFieldOptions<'input'>

/**
 * An accessible input component implemented using react-aria's [useTextField](https://react-spectrum.adobe.com/react-aria/useTextField.html)
 */
export const Input = (props: InputProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const {
    label,
    description,
    errorMessage,
    prefix,
    suffix,
    tooltip,
    isValidationError = false,
    validationErrorMessage = '',
  } = props
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  const { color: unusedColor, ...allProps } = mergeProps(inputProps, focusProps)

  return (
    <Stack direction="vertical" space="none">
      {(label ?? tooltip) && (
        <Stack direction="horizontal" space="small" align="center">
          {label && (
            <Text {...labelProps} as="label" weight="light" lineHeight="large" color="gray600">
              {label}
            </Text>
          )}
          {tooltip && (
            <Tooltip content={tooltip} delay={0}>
              <Icon>
                <Info />
              </Icon>
            </Tooltip>
          )}
        </Stack>
      )}

      <Box
        display="flex"
        flexDirection="row"
        className={styles.root({
          disabled: inputProps.disabled,
          error: !!errorMessage,
          readOnly: inputProps.readOnly,
          isFocused: isFocusVisible,
        })}
      >
        {prefix && (
          <Box aria-hidden="true" as="span" className={[styles.affix, styles.prefix]}>
            {prefix}
          </Box>
        )}
        <input
          ref={ref}
          className={styles.input({
            disabled: inputProps.disabled,
            hasPrefix: !!prefix,
            hasSuffix: !!suffix,
          })}
          {...allProps}
        />
        {suffix && (
          <Box aria-hidden="true" as="span" className={[styles.affix, styles.suffix]}>
            {suffix}
          </Box>
        )}
      </Box>

      <div style={{ height: '28px' }} className={styles.error} {...errorMessageProps}>
        {isValidationError && validationErrorMessage && <>{validationErrorMessage}</>}
      </div>

      {errorMessage ? (
        <div className={styles.error} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={styles.description} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </Stack>
  )
}
