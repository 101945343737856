import { createContext, type ReactElement, useContext, useRef } from 'react'
import {
  type AriaRadioGroupProps,
  type AriaRadioProps,
  useFocusRing,
  useRadio,
  useRadioGroup,
  VisuallyHidden,
} from 'react-aria'
import { type RadioGroupState, useRadioGroupState } from 'react-stately'

import { Box } from '../Box'
import { Text } from '../Text'

import { type RadioVariants } from './Radio.css'
import * as styles from './Radio.css'

export type RadioProps = AriaRadioProps & RadioVariants
export type RadioGroupProps = {
  children: ReactElement<RadioProps> | ReactElement<RadioProps>[]
  /**
   * The error message for the `CheckboxGroup`
   */
  errorMessage?: any
} & AriaRadioGroupProps

const RadioContext = createContext({} as RadioGroupState)

export const Radio = (props: RadioProps) => {
  const { children, direction, hasBorder, value } = props
  const state = useContext(RadioContext)
  const ref = useRef(null)
  const { inputProps } = useRadio(props, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  const isSelected = state.selectedValue === value
  const strokeWidth = isSelected ? 6 : 2

  return (
    <Box
      as="label"
      className={styles.root({
        direction,
        hasBorder,
        isDisabled: inputProps.disabled,
        isSelected,
      })}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={24} height={24} aria-hidden="true">
        <circle
          cx={12}
          cy={12}
          r={8 - strokeWidth / 2}
          fill="none"
          strokeWidth={strokeWidth}
          className={styles.radio({
            isSelected,
            isFocused: isFocusVisible,
          })}
        />
      </svg>
      {children}
    </Box>
  )
}

const RadioGroup = (props: RadioGroupProps) => {
  const { children, label, errorMessage, description } = props
  const state = useRadioGroupState(props)
  const { radioGroupProps, labelProps, errorMessageProps, descriptionProps } = useRadioGroup(
    props,
    state,
  )

  return (
    <div {...radioGroupProps}>
      {label && (
        <Text
          {...labelProps}
          as="span"
          weight="light"
          lineHeight="medium"
          color={{ darkMode: 'gray600', lightMode: 'black' }}
        >
          {label}
        </Text>
      )}
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      {errorMessage ? (
        <div className={styles.error} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={styles.description} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </div>
  )
}

Radio.Group = RadioGroup
