import { useRef } from 'react'
import { type AriaCheckboxProps, useCheckbox, useFocusRing, VisuallyHidden } from 'react-aria'
import { useToggleState } from 'react-stately'
import { type Prettify } from 'plunger'

import { Box } from '../Box'

import * as styles from './Checkbox.css'

export type CheckboxProps = {
  errorMessage?: any
} & Prettify<AriaCheckboxProps>

/**
 * Accessible implementation of a checkbox input
 */
export const Checkbox = (props: CheckboxProps) => {
  const { children, isReadOnly, isIndeterminate } = props
  const state = useToggleState(props)
  const ref = useRef<HTMLInputElement>(null)
  const { inputProps } = useCheckbox({ ...props, isIndeterminate }, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <Box
      as="label"
      display="flex"
      alignItems="center"
      className={styles.root({ disabled: inputProps.disabled ?? isReadOnly })}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg
        viewBox="0 0 24 24"
        aria-hidden="true"
        className={styles.checkbox({
          selected: state.isSelected || isIndeterminate,
          focused: isFocusVisible,
        })}
      >
        {state.isSelected && (
          <polyline points="20 6 9 17 4 12" strokeWidth="2px" stroke="white" fill="none" />
        )}
        {!state.isSelected && isIndeterminate && (
          <polyline points="3,12 21,12 " strokeWidth="2px" stroke="white" fill="none" />
        )}
      </svg>
      {children}
    </Box>
  )
}
