import { createContext, type ReactElement, useContext, useRef } from 'react'
import {
  type AriaCheckboxGroupItemProps,
  type AriaCheckboxGroupProps,
  useCheckboxGroup,
  useCheckboxGroupItem,
  useFocusRing,
  VisuallyHidden,
} from 'react-aria'
import { type CheckboxGroupState, useCheckboxGroupState } from 'react-stately'
import { type Prettify } from 'plunger'

import { Box } from '../Box'
import { Loading } from '../Loading'
import { Stack, type StackProps } from '../Stack'
import { Text } from '../Text'

import * as styles from './CheckboxGroup.css'

const CheckboxGroupContext = createContext<CheckboxGroupState | undefined>(undefined)

type CheckboxGroupProps = Prettify<
  {
    children: ReactElement<CheckboxProps> | ReactElement<CheckboxProps>[]
    /**
     * Control the layout direction of the `Checkboxes` within the group
     * @default 'vertical'
     */
    direction?: StackProps['direction']
    /**
     * Add a loading indicator to the `CheckboxGroup` while you are fetching data to render the `Checkboxes`.
     * @default false
     */
    isLoading?: boolean

    /**
     * The label for the `CheckboxGroup`
     */
    label?: string
    /**
     * The description for the `CheckboxGroup`
     */
    description?: string
    /**
     * The error message for the `CheckboxGroup`
     */
    errorMessage?: any
  } & AriaCheckboxGroupProps
>

/**
 * Accessible implementation of a checkbox group input
 */
export function CheckboxGroup(props: CheckboxGroupProps) {
  const {
    children,
    label,
    description,
    errorMessage,
    direction = 'vertical',
    isLoading = false,
  } = props
  const state = useCheckboxGroupState(props)
  const { groupProps, labelProps, descriptionProps, errorMessageProps } = useCheckboxGroup(
    props,
    state,
  )

  return (
    <div {...groupProps}>
      {label && (
        <Text {...labelProps} as="span" weight="light" lineHeight="medium" color="gray600">
          {label}
        </Text>
      )}
      <CheckboxGroupContext.Provider value={state}>
        <Stack
          direction={direction}
          space={direction === 'vertical' ? 'xsmall' : 'medium'}
          align="start"
        >
          {isLoading ? <Loading /> : children}
        </Stack>
      </CheckboxGroupContext.Provider>
      {errorMessage ? (
        <div className={styles.error} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={styles.description} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </div>
  )
}
type CheckboxProps = AriaCheckboxGroupItemProps

/**
 * Custom `checkbox` only to be used as part of the `CheckboxGroup`
 */
function Checkbox(props: CheckboxProps) {
  const { children, value, isIndeterminate, isReadOnly } = props
  const state = useContext(CheckboxGroupContext)
  if (state === undefined)
    throw new Error('CheckboxGroup.Checkbox can only be used as children of a CheckboxGroup')
  const ref = useRef(null)
  const { inputProps } = useCheckboxGroupItem(props, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  const isSelected = state.isSelected(value)

  return (
    <Box
      as="label"
      display="flex"
      alignItems="center"
      className={styles.root({ isDisabled: inputProps.disabled ?? isReadOnly })}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg
        viewBox="0 0 24 24"
        aria-hidden="true"
        className={styles.checkbox({
          isSelected: isSelected || isIndeterminate,
          isFocused: isFocusVisible,
        })}
      >
        {isSelected && (
          <polyline points="20 6 9 17 4 12" strokeWidth="2px" stroke="white" fill="none" />
        )}
        {!isSelected && isIndeterminate && (
          <polyline points="3,12 21,12 " strokeWidth="2px" stroke="white" fill="none" />
        )}
      </svg>
      {children}
    </Box>
  )
}

CheckboxGroup.Checkbox = Checkbox
